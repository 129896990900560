<!-- 使用手册 -->
<template>
  <div class="wrap">
    <img v-if="odd_job == 0" src="https://oss.hr24.com.cn/public/02a172eba43a405fa7daf2112dcbbadb.jpg?BUCKET=jpg-bucket" alt />
    <img v-if="odd_job == 1" src="https://oss.hr24.com.cn/public/e1a557080add4bc2978259d0633780ab.jpg?BUCKET=jpg-bucket" alt />
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { imgs } from "@/api/login.js";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      image: "",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.odd_job = sessionStorage.getItem('odd_job')
    imgs().then((res) => {
      console.log(res);
      if (!res.code) {
        this.image = res.data.url;
      }
    });
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style  scoped>
/* //@import url(); 引入公共css类 */
.wrap {
  width: 1200px;
  padding: 30px 50px 100px;

  margin: 0 auto;
  text-align: center;
}
.wrap img {
  width: 100%;
}
</style>